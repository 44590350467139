<template>
  <div
    class="fixed bottom-0 left-0 w-auto m-12 bg-white z-50 mb-12 mr-6"
    style="z-index:999;"
  >
    <div class="bg-gray-900 px-3 pr-0 sm:px-4 sm:pr-1 flex justify-between items-center">
      <div class="text-white truncate w-full text-xs sm:text-sm py-4">
        Drop file to add to import to scene
        <span class="block italic text-xs text-gray-400">.glb, .gltf, .fbx, .obj supported</span>
      </div>
      <div class="actions flex">
        <a
          href="#"
          class="text-blue-500 focus:outline-none hover:bg-gray-800 px-6 py-3"
        >
          <fa-icon icon="upload" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
